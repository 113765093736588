<div class="todays-revenue-box bg-white">
    <h5 class="fw-medium">
        Today’s Revenue
    </h5>
    <div class="info position-relative">
        <div class="chart">
            <img src="assets/images/icons/line.svg" alt="chart">
        </div>
        <h6 class="fw-medium">
            $12,095
        </h6>
        <span class="d-inline-block text-gray position-relative">
            5.08% <i class="ri-arrow-down-s-line"></i>
        </span>
    </div>
</div>