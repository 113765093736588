<div class="expected-earnings-box bg-white">
    <h5 class="fw-medium">
        Expected Earnings
    </h5>
    <div class="info">
        <div class="d-flex align-items-center justify-content-between">
            <span class="d-block text-gray">
                $12,095
            </span>
            <span class="d-block fw-medium">
                $45,000
            </span>
        </div>
        <mat-progress-bar mode="determinate" value="50"></mat-progress-bar>
    </div>
</div>