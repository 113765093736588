<div class="table-responsive">

    <div class="flex justify-between items-center p-4">
        <mat-card-title>
            <h5 class="mt-0 mb-0 text-gray">
                Mis empresas
            </h5>

        </mat-card-title>
        
        <button style="margin-right: 6px;" mat-raised-button color="primary" class="" (click)="openCreateCompanyDialog()">
            <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="add"></mat-icon>                        
            Agregar empresa
        </button> 
    </div>
    


    <table mat-table [dataSource]="dataSource" class="main-table bg-blue-500">
        <!-- Company Column -->
        <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="hover:underline cursor-pointer" (click)="goToEmpresa(element.rut)"> {{element.name}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"> 
                <div class="button-container">
                    <button 
                        style="margin-right: 6px;" 
                        mat-stroked-button color="primary" 
                        class=""
                        (click)="goToForm(element.rut)">
                            <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="edit"></mat-icon>                        
                            Hacer test
                    </button> 
                    <button mat-stroked-button color="warn" (click)="openConfirmDialog(element)">
                        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="delete"></mat-icon>  
                        Eliminar
                    </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

</div>
<mat-paginator class="mt-4" [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
