import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { rutValidate } from 'rut-helpers';


// Custom validator para RUTs
export function gtpRutValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    // Chequeo si no es un rut valido
    if (!rutValidate(value)) {
      return { rutValidate: true };
    }

    // Devuelvo null si pasa la validación
    return null;
  };
}
