import { Component, ViewChild, AfterViewInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon'; 
import { MatCardModule } from '@angular/material/card';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Company } from '../../models/Company.model';
import { SpanishPaginator } from '../../../shared/components/paginator/spanish-paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { CreateCompanyDialogComponent } from '../../../shared/components/create-company-dialog/create-company-dialog.component';
import { Router } from '@angular/router';

let ELEMENT_DATA: Company[] = [
    { name: "Empresa SPA", rut: "123-4" },
    { name: "Empresa Warware", rut: "122-4" },
    { name: "Intrexeca", rut: "1253-4" },
    { name: "Amplifica SA", rut: "134523-4" },
];

@Component({
  selector: 'app-companies-table', 
  standalone: true,
  providers: [
    { provide: MatPaginatorIntl, useClass: SpanishPaginator }, // Use the custom paginator
  ],
  imports: [MatTableModule, MatButtonModule, MatIconModule, MatCardModule, MatPaginatorModule],
  templateUrl: './companies-table.component.html',
  styleUrls: ['./companies-table.component.scss']
})
export class CompaniesTableComponent implements AfterViewInit {
  displayedColumns: string[] = ['company', 'actions'];
  dataSource = new MatTableDataSource(ELEMENT_DATA); // Use MatTableDataSource

  readonly dialog = inject(MatDialog);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private _router: Router) {
    
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator; // Assign paginator to dataSource
  }

  openConfirmDialog(company: Company) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Eliminar empresa',
        message: `¿Esta seguro que desea eliminar la empresa ${company.name}, de rut ${company.rut}?`,
        textBoton: ['Eliminar', 'Cancelar'],
        invertColors: true,
        // textTimer: env.inactivity.countdown + 1
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const rutToDelete = company.rut;
        const cleanedArray = ELEMENT_DATA.filter(company => company.rut !== rutToDelete)
        ELEMENT_DATA = cleanedArray
        this.dataSource = new MatTableDataSource(cleanedArray);
      }
    });
  }

  openCreateCompanyDialog() {
    const dialogRef = this.dialog.open(CreateCompanyDialogComponent, {
      // minHeight: '400px',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(result) {
        ELEMENT_DATA.push(result)
        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
      }
    })
  }

  goToEmpresa(rut: string) {
    this._router.navigate(['tests',rut])
  }

  goToForm(rut: string) {
    this._router.navigate(['form/null/' + rut])
  }
}
