import * as i0 from '@angular/core';
import { input, output, signal, viewChild, inject, NgZone, PLATFORM_ID, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { asapScheduler } from 'rxjs';
const _c0 = ["chart"];
class ChartComponent {
  constructor() {
    this.chart = input();
    this.annotations = input();
    this.colors = input();
    this.dataLabels = input();
    this.series = input();
    this.stroke = input();
    this.labels = input();
    this.legend = input();
    this.markers = input();
    this.noData = input();
    this.fill = input();
    this.tooltip = input();
    this.plotOptions = input();
    this.responsive = input();
    this.xaxis = input();
    this.yaxis = input();
    this.forecastDataPoints = input();
    this.grid = input();
    this.states = input();
    this.title = input();
    this.subtitle = input();
    this.theme = input();
    this.autoUpdateSeries = input(true);
    this.chartReady = output();
    // If consumers need to capture the `chartInstance` for use, consumers
    // can access the component instance through `viewChild` and use `computed`
    // or `effect` on `component.chartInstance()` to monitor its changes and
    // recompute effects or computations whenever `chartInstance` is updated.
    this.chartInstance = signal(null);
    this.chartElement = viewChild.required("chart");
    this.ngZone = inject(NgZone);
    this.isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  }
  ngOnChanges(changes) {
    if (!this.isBrowser) return;
    this.ngZone.runOutsideAngular(() => {
      asapScheduler.schedule(() => this.hydrate(changes));
    });
  }
  ngOnDestroy() {
    this.destroy();
  }
  hydrate(changes) {
    const shouldUpdateSeries = this.autoUpdateSeries() && Object.keys(changes).filter(c => c !== "series").length === 0;
    if (shouldUpdateSeries) {
      this.updateSeries(this.series(), true);
      return;
    }
    this.createElement();
  }
  async createElement() {
    const {
      default: ApexCharts
    } = await import('apexcharts');
    window.ApexCharts ||= ApexCharts;
    const options = {};
    const properties = ["annotations", "chart", "colors", "dataLabels", "series", "stroke", "labels", "legend", "fill", "tooltip", "plotOptions", "responsive", "markers", "noData", "xaxis", "yaxis", "forecastDataPoints", "grid", "states", "title", "subtitle", "theme"];
    properties.forEach(property => {
      const value = this[property]();
      if (value) {
        options[property] = value;
      }
    });
    this.destroy();
    const chartInstance = this.ngZone.runOutsideAngular(() => new ApexCharts(this.chartElement().nativeElement, options));
    this.chartInstance.set(chartInstance);
    this.render();
    this.chartReady.emit({
      chartObj: chartInstance
    });
  }
  render() {
    return this.ngZone.runOutsideAngular(() => this.chartInstance()?.render());
  }
  updateOptions(options, redrawPaths, animate, updateSyncedCharts) {
    return this.ngZone.runOutsideAngular(() => this.chartInstance()?.updateOptions(options, redrawPaths, animate, updateSyncedCharts));
  }
  updateSeries(newSeries, animate) {
    return this.ngZone.runOutsideAngular(() => this.chartInstance()?.updateSeries(newSeries, animate));
  }
  appendSeries(newSeries, animate) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.appendSeries(newSeries, animate));
  }
  appendData(newData) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.appendData(newData));
  }
  highlightSeries(seriesName) {
    return this.ngZone.runOutsideAngular(() => this.chartInstance()?.highlightSeries(seriesName));
  }
  toggleSeries(seriesName) {
    return this.ngZone.runOutsideAngular(() => this.chartInstance()?.toggleSeries(seriesName));
  }
  showSeries(seriesName) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.showSeries(seriesName));
  }
  hideSeries(seriesName) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.hideSeries(seriesName));
  }
  resetSeries() {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.resetSeries());
  }
  zoomX(min, max) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.zoomX(min, max));
  }
  toggleDataPointSelection(seriesIndex, dataPointIndex) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.toggleDataPointSelection(seriesIndex, dataPointIndex));
  }
  destroy() {
    this.chartInstance()?.destroy();
    this.chartInstance.set(null);
  }
  setLocale(localeName) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.setLocale(localeName));
  }
  paper() {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.paper());
  }
  addXaxisAnnotation(options, pushToMemory, context) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.addXaxisAnnotation(options, pushToMemory, context));
  }
  addYaxisAnnotation(options, pushToMemory, context) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.addYaxisAnnotation(options, pushToMemory, context));
  }
  addPointAnnotation(options, pushToMemory, context) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.addPointAnnotation(options, pushToMemory, context));
  }
  removeAnnotation(id, options) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.removeAnnotation(id, options));
  }
  clearAnnotations(options) {
    this.ngZone.runOutsideAngular(() => this.chartInstance()?.clearAnnotations(options));
  }
  dataURI(options) {
    return this.chartInstance()?.dataURI(options);
  }
  /** @nocollapse */
  static {
    this.ɵfac = function ChartComponent_Factory(t) {
      return new (t || ChartComponent)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ChartComponent,
      selectors: [["apx-chart"]],
      viewQuery: function ChartComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.chartElement, _c0, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      inputs: {
        chart: [i0.ɵɵInputFlags.SignalBased, "chart"],
        annotations: [i0.ɵɵInputFlags.SignalBased, "annotations"],
        colors: [i0.ɵɵInputFlags.SignalBased, "colors"],
        dataLabels: [i0.ɵɵInputFlags.SignalBased, "dataLabels"],
        series: [i0.ɵɵInputFlags.SignalBased, "series"],
        stroke: [i0.ɵɵInputFlags.SignalBased, "stroke"],
        labels: [i0.ɵɵInputFlags.SignalBased, "labels"],
        legend: [i0.ɵɵInputFlags.SignalBased, "legend"],
        markers: [i0.ɵɵInputFlags.SignalBased, "markers"],
        noData: [i0.ɵɵInputFlags.SignalBased, "noData"],
        fill: [i0.ɵɵInputFlags.SignalBased, "fill"],
        tooltip: [i0.ɵɵInputFlags.SignalBased, "tooltip"],
        plotOptions: [i0.ɵɵInputFlags.SignalBased, "plotOptions"],
        responsive: [i0.ɵɵInputFlags.SignalBased, "responsive"],
        xaxis: [i0.ɵɵInputFlags.SignalBased, "xaxis"],
        yaxis: [i0.ɵɵInputFlags.SignalBased, "yaxis"],
        forecastDataPoints: [i0.ɵɵInputFlags.SignalBased, "forecastDataPoints"],
        grid: [i0.ɵɵInputFlags.SignalBased, "grid"],
        states: [i0.ɵɵInputFlags.SignalBased, "states"],
        title: [i0.ɵɵInputFlags.SignalBased, "title"],
        subtitle: [i0.ɵɵInputFlags.SignalBased, "subtitle"],
        theme: [i0.ɵɵInputFlags.SignalBased, "theme"],
        autoUpdateSeries: [i0.ɵɵInputFlags.SignalBased, "autoUpdateSeries"]
      },
      outputs: {
        chartReady: "chartReady"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 0,
      consts: [["chart", ""]],
      template: function ChartComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", null, 0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChartComponent, [{
    type: Component,
    args: [{
      selector: "apx-chart",
      template: `<div #chart></div>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true
    }]
  }], null, null);
})();
const declarations = [ChartComponent];
class NgApexchartsModule {
  /** @nocollapse */static {
    this.ɵfac = function NgApexchartsModule_Factory(t) {
      return new (t || NgApexchartsModule)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgApexchartsModule
    });
  }
  /** @nocollapse */
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgApexchartsModule, [{
    type: NgModule,
    args: [{
      imports: [declarations],
      exports: [declarations]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ng-apexcharts
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ChartComponent, NgApexchartsModule };
