import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmModel } from '../confirm-dialog/confirm-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { FeathericonsModule } from '../../../icons/feathericons/feathericons.module';
import { CustomErrorstatematcherComponent } from '../../../ui-elements/input/custom-errorstatematcher/custom-errorstatematcher.component';
import { MatCardModule } from '@angular/material/card';
import { gtpRutValidator } from '../../validators/gtp-rut-validator';

@Component({
  selector: 'app-create-company-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    CustomErrorstatematcherComponent, 
    ReactiveFormsModule, 
    FeathericonsModule,
    MatCardModule,
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './create-company-dialog.component.html',
  styleUrl: './create-company-dialog.component.scss'
})
export class CreateCompanyDialogComponent implements OnInit {

  nameControl: FormControl = new FormControl('', [Validators.required]);
  rutControl: FormControl = new FormControl('', [Validators.required, gtpRutValidator()]);
  onboardingForm: FormGroup = this.formBuilder.group({
    rut: this.rutControl,
    sii: this.nameControl
  });
  
  constructor(
    public dialogRef: MatDialogRef<CreateCompanyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModel,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit() {

  }

  onNoClick() {
    this.dialogRef.close()
  }

  onCreate() {
    const name: string = this.nameControl.value
    const rut: string = this.rutControl.value

    this.dialogRef.close({ name, rut })
  }
}
