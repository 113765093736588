<div class="week-report-box bg-white">
    <h5 class="fw-medium">
        Week Report
    </h5>
    <div class="info position-relative">
        <div class="chart">
            <img src="assets/images/icons/pie3.svg" alt="chart">
        </div>
        <h6 class="fw-medium">
            $14k
        </h6>
        <span class="d-inline-block text-gray">
            Revenue
        </span>
    </div>
</div>