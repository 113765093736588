<div class="average-value-box bg-white">
    <h5 class="fw-medium">
        Average Value
    </h5>
    <div class="info position-relative">
        <div class="chart">
            <img src="assets/images/icons/bars3.svg" alt="chart">
        </div>
        <h6 class="fw-medium">
            $80.5
        </h6>
        <span class="d-inline-block text-gray">
            VAL
        </span>
    </div>
</div>