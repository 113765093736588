import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { Router, RouterLink } from '@angular/router';
import { TodaysOrderComponent } from '../../dashboard/ecommerce/website-overview/todays-order/todays-order.component';
import { TodaysRevenueComponent } from '../../dashboard/ecommerce/website-overview/todays-revenue/todays-revenue.component';
import { AverageValueComponent } from '../../dashboard/ecommerce/website-overview/average-value/average-value.component';
import { AllSpendingComponent } from '../../dashboard/ecommerce/website-overview/all-spending/all-spending.component';
import { ExpectedEarningsComponent } from '../../dashboard/ecommerce/website-overview/expected-earnings/expected-earnings.component';
import { WeekReportComponent } from '../../dashboard/ecommerce/website-overview/week-report/week-report.component';
import { BasicTableComponent } from '../../ui-elements/table/basic-table/basic-table.component';
import { CompaniesTableComponent } from './companies-table/companies-table.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [RouterLink, MatCardModule, TodaysOrderComponent, TodaysRevenueComponent, AverageValueComponent, AllSpendingComponent, ExpectedEarningsComponent, WeekReportComponent, BasicTableComponent, HomeComponent, CompaniesTableComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

  constructor(private _router: Router) {
    this._router.navigate(['/tests/123-4']) 
  }
}
